import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AppContext from '../context/AppContext';
import { linkResolver } from '../utils/linkResolver';
import { Link } from 'gatsby';

// component styles are used for plugin
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  padding: 12.5,
  fontSize: 12,
  color: '#444444',
  textTransform: 'uppercase',

  // change background colour if dragging
  background: isDragging ? '#F3F3F3' : 'white',

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'white' : 'white',
  width: 210,
  borderRadius: 8,
  position: 'absolute',
  zIndex: 20
});

class SortablePlaylist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this.props.playlist
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(this.state.items, result.source.index, result.destination.index);

    this.setState({
      items
    });

    this.props.updateTourPlaylist(items);
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => {
            return (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                className="sortable-playlist-ul"
              >
                {this.state.items.map((item, index) => (
                  <AppContext.Consumer>
                    {app => {
                      let currentTitle = app.currentTitle;
                      let isActive = app.currentTourTitle === item.tour_title[0].text;

                      return (
                        <Draggable key={item.meta_title} draggableId={`item-${index}`} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                            >
                              <Link
                                to={
                                  `${linkResolver(item._meta)}` +
                                  `${this.props.location !== undefined ? '?back=' + this.props.location : ''}`
                                }
                                onClick={() => app.toggleDraggablePlaylist(item)}
                                className="tour-playlist-link"
                              >
                                <div className={` ${isActive ? 'active' : ''}`}>
                                  {/* <div className={`sort-playlist-item ${isActive ? 'active' : ''}`}> */}
                                  <div className="">
                                    {/* <div className="sort-playlist-title"> */}
                                    <span>{item.tour_title[0].text}</span> {isActive && <span>&#x2713;</span>}
                                  </div>
                                </div>
                              </Link>
                            </div>
                          )}
                        </Draggable>
                      );
                    }}
                  </AppContext.Consumer>
                ))}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default SortablePlaylist;
