import React, { Component } from 'react';

export default class SliderModal extends Component {

  constructor(props) {
    super(props);

    this.fixPositionBody = this.fixPositionBody.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  fixPositionBody = () => {
    this.props.goFullScreen ? document.body.style.position = 'fixed' : document.body.style.position = 'static';
  };

  closeModal = () => {
    this.props.toggleGoFullScreen();
  };

  render() {
    let { image, altText, desktopOrMobile, goFullScreen } = this.props;

    return (
      <div className="full-screen-modal-container">
        <div className={`full-screen-overlay ${goFullScreen ? '' : 'hide'}`}>
          <div key={Math.floor(Math.random() * 20)} id="modal-container" className="modal-container">
            <div className="modal-wrapper">
              <div className="modal-header">
                <span className="close-modal-btn" onClick={this.closeModal}>×</span>
              </div>
              <div className="modal-body">
                <div className={`img-container ${desktopOrMobile}`}>
                  <img src={image} alt={altText} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
