import React, { Fragment, useState, useEffect } from 'react';
import { graphql /* Link */ } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { RichText } from 'prismic-reactjs';
import AppContext from '../context/AppContext';
import SortablePlaylist from '../components/SortablePlaylist';
import previousTourButton from '../_images/global/tour-playlist-control-prev.svg';
import previousTourButtonHover from '../_images/global/tour-playlist-control-prev-hover.svg';
import tourPlaylistTimer from '../_images/global/tour-playlist-control-timer.svg';
import tourPlaylistTimerHover from '../_images/global/tour-playlist-control-timer-hover.svg';
import tourPlaylistPlay from '../_images/global/tour-playlist-control-play.svg';
import tourPlaylistPlayHover from '../_images/global/tour-playlist-control-play-hover.svg';
import nextTourButton from '../_images/global/tour-playlist-control-next.svg';
import nextTourButtonHover from '../_images/global/tour-playlist-control-next-hover.svg';
import tourPlaylistSelectIcon from '../_images/global/tour-playlist-select-icon.svg';
import tourPlaylistSelectIconHover from '../_images/global/tour-playlist-select-icon-hover.svg';
import tourPlaylistPause from '../_images/global/tour-playlist-control-pause.svg';
import tourPlaylistPauseHover from '../_images/global/tour-playlist-control-pause-hover.svg';
import SlickSlider from '../components/SlickSlider';
import StepsCounter from '../components/StepsCounter';
import PlaylistIntervals from '../components/PlaylistIntervals';
import SlideShowToggle from '../components/SlideShowToggle';
import Button from '../components/Button';
import PasswordModal from '../components/PasswordModal';

const Tour = ({ data, pageContext, location, backToToursLink }) => {
  let { tour, allTour_pages, allGlobal_navigations, allLanguages } = data.prismic;
  let labels = allTour_pages.edges[0].node;
  let globalNavCTA = allGlobal_navigations.edges[0].node.global_cta;

  let languages = allLanguages.edges.map(x => x.node);
  let browseMoreToursLink = '';
  let backLinkCheck = location.search.indexOf('?back=');
  let backLink = backLinkCheck >= 0 ? location.search.split('?back=')[1] : location.search;
  // set browseMoreToursLink by language and if location.seach is not empty string use backLink as the URL Path
  // this now also accounts for any other params sent in the search i.e. ad tags etc

  let generateBMTLink = (lang, backLink) => {
    if (lang === 'en-us' && location !== undefined && location.search === '') {
      browseMoreToursLink = '/';
    }
    if (lang !== 'en-us' && location !== undefined && location.search === '') {
      browseMoreToursLink = `/${lang}`;
    }
    if (lang === 'en-us' && location !== undefined && location.search) {
      browseMoreToursLink = backLink;
    }
    if (lang !== 'en-us' && location !== undefined && location.search) {
      browseMoreToursLink = `/${backLink}`;
    }
    return browseMoreToursLink;
  };

  const [pbActive, setPencilBanner] = useState(false);

  useEffect(() => {
    if (document.getElementsByClassName('pencil-banner').length > 0) {
      setPencilBanner(true);
    }
  }, []);

  // generate link for back to button
  generateBMTLink(pageContext.lang, backLink);

  return (
    <Fragment>
      <div className="password-modal-container">
        <AppContext.Consumer>
          {app => {
            return (
              <>
                {tour !== null && tour.password !== null && (
                  <PasswordModal password={tour.password} tourId={tour._meta.id} />
                )}
              </>
            );
          }}
        </AppContext.Consumer>
      </div>

      <Layout location={'tour'} lang={pageContext.lang} languages={languages}>
        {tour ? (
          <Fragment>
            <SEO title={tour.meta_title} description={tour.meta_description} lang={pageContext.lang} />
            <div className={`container main-container ${tour.password === null ? '' : 'password-protected'}`}>
              <div className={`tour-navigation ${pbActive ? 'pencil-banner-active' : ''}`}>
                <AppContext.Consumer>
                  {app => {
                    return (
                      <div className="browse-more-tours-btn" onClick={() => app.clearPlaylistFromTour('tour')}>
                        <Button
                          type="text"
                          text={labels.browse_more_tours}
                          link={browseMoreToursLink}
                          location="tour"
                          icon="null"
                        />
                      </div>
                    );
                  }}
                </AppContext.Consumer>
              </div>
              <div className="tour-options-wrapper desktop mobile">
                <AppContext.Consumer>
                  {app => {
                    return (
                      <div className={`playlist-options-wrapper ${app.showPlaylist ? '' : 'hide'}`}>
                        <SortablePlaylist
                          tours={app.tours}
                          playlist={app.playlist}
                          updateTourPlaylist={app.updateTourPlaylist}
                          setCurrentTourTitle={app.setCurrentTourTitle}
                          location={backLink}
                        />
                      </div>
                    );
                  }}
                </AppContext.Consumer>
              </div>
              <AppContext.Consumer>
                {app => (
                  <SlickSlider
                    tour={data.prismic.tour}
                    slides={data.prismic.tour.steps}
                    currentSlide={app.currentSlide}
                    slideInterval={app.defaultTimeInterval}
                    checkSlideIndexNext={app.checkSlideIndexNext}
                    checkSlideIndexPrev={app.checkSlideIndexPrev}
                    labels={labels}
                    lang={pageContext.lang}
                    app={app}
                    globalNavCTA={globalNavCTA}
                    location={location}
                    currentTourTitle={data.prismic.tour.tour_title[0].text}
                  />
                )}
              </AppContext.Consumer>
            </div>
          </Fragment>
        ) : (
          undefined
        )}
      </Layout>
    </Fragment>
  );
};

export const query = graphql`
  query PageQuery($uid: String!, $lang: String!) {
    prismic {
      tour(uid: $uid, lang: $lang) {
        ...TourFragment
      }
      allLanguages(lang: $lang) {
        edges {
          node {
            ...LanguageFragment
          }
        }
      }
      allTour_pages(lang: $lang) {
        edges {
          node {
            ...TourPageLabelsFragment
          }
        }
      }
      allGlobal_navigations(lang: $lang) {
        edges {
          node {
            ...GlobalNavigationFragment
          }
        }
      }
    }
  }
`;

export default Tour;
