import React, { Component } from 'react';
import AppContext from '../context/AppContext';
import PlaylistIntervals from '../components/PlaylistIntervals';

class SlideShowToggle extends Component {
  render() {
    return (
      <div className="slideshow-toggle-container" key="slideshow-toggle">
        <AppContext.Consumer>
          {app => {
            let playOrPauseSlideshow = e => {
              if (e.target.checked) {
                app.updateSlickSettings({ ...app.settings, autoplay: true });
                app.startPresentationMode();
                app.slider.slickPlay();
              } else {
                app.updateSlickSettings({ ...app.settings, autoplay: false });
                app.stopPresentationMode();
                app.slider.slickPause();
              }
            };
            return (
              <div className="toggle-block">
                <div className="toggle-title">
                  {this.props.slideShowText}{' '}
                  <span>
                    <label className="tour-control-switch">
                      <input
                        type="checkbox"
                        onChange={e => {
                          playOrPauseSlideshow(e);
                        }}
                        checked={app.presentationMode}
                      />
                      <span className="slider round"></span>
                    </label>
                  </span>
                  {app.presentationMode && (
                    <>
                      <span className="pipe"> | </span>{' '}
                      <span className="current-slide-interval">{app.defaultTimeInterval / 1000} secs</span>
                    </>
                  )}
                </div>
                {app.presentationMode && (
                  <button
                    className="tour-control-btn tour-timer-btn"
                    onClick={() => {
                      app.toggleTimerIntervals();
                    }}
                  >
                    <svg width="12px" height="10px" viewBox="0 0 8 5" version="1.1">
                      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="D---Homepage" transform="translate(-1202.000000, -8.000000)" fill="#59575c">
                          <g id="Header">
                            <g id="Language">
                              <g id="Group-3" transform="translate(1202.000000, 8.000000)">
                                <path
                                  d="M3.7344,4.5437 L0.109733333,0.863033333 C-0.0369333333,0.716366667 -0.0369333333,0.479033333 0.109733333,0.332366667 L0.331733333,0.110366667 C0.479066667,-0.0369666667 0.7164,-0.0369666667 0.863066667,0.110366667 L3.99973333,3.30636667 L7.13706667,0.113033333 C7.28373333,-0.0336333333 7.52173333,-0.0336333333 7.66773333,0.113033333 L7.88973333,0.335033333 C8.0364,0.4817 8.0364,0.7197 7.88973333,0.866366667 L4.26573333,4.54703333 C4.11906667,4.69036667 3.88106667,4.69036667 3.7344,4.5437"
                                  id="Fill-1"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </button>
                )}
                <div className="playlist-intervals-wrapper">
                  <div className="plalist-interval-options">
                    <AppContext.Consumer>
                      {app => {
                        if (app.showTimeIntervals) {
                          return <PlaylistIntervals />;
                        }
                      }}
                    </AppContext.Consumer>
                  </div>
                </div>
              </div>
            );
          }}
        </AppContext.Consumer>
      </div>
    );
  }
}

export default SlideShowToggle;
