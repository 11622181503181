import React, { Component } from 'react';
import AppContext from '../context/AppContext';

class StepsCounter extends Component {
  render() {
    const { totalSteps } = this.props;

    return (
      <AppContext.Consumer>
        {app => (
          <div className="steps-container">
            Step {app.currentSlide} of {totalSteps}
          </div>
        )}
      </AppContext.Consumer>
    );
  }
}
export default StepsCounter;
