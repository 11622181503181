import React, { Component, Fragment } from 'react';
import Slider from 'react-slick';
import { RichText } from 'prismic-reactjs';
import AppContext from '../context/AppContext';
import Button from '../components/Button';
import LargeMonitor from '../_images/global/large-monitor.png';
import MobileDeviceShadow from '../_images/global/mobile-device-shadow-2.png';
import SliderModal from '../components/SliderModal';
import { Player, ControlBar, LoadingSpinner } from 'video-react';
import StepsCounter from '../components/StepsCounter';
import SlideShowToggle from '../components/SlideShowToggle';
import PlaylistMenuBtn from '../_images/global/playlist-menu-btn.svg';
import ClosePlaylistBtn from '../_images/global/close-playlist.svg';

export default class SlickSlider extends Component {
  state = {
    oldSlide: 0,
    activeSlide: 0,
    activeSlide2: 0,
    mobile: null,
    zoomMode: false,
    goFullScreen: false,
    verifiedPassword: false
  };

  autoPlayIntervals = ['10000', '15000', '20000'];

  mobileDevice = null;
  tvDesktop = null;

  settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    fade: true,
    cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
    beforeChange: (current, next) => this.setState({ oldSlide: current, activeSlide: next }),
    afterChange: current => this.setState({ activeSlide2: current }),
    swipe: false,
    lazyLoad: true
  };

  componentDidMount() {
    // need to check if current tour is not first in list
    // if not set auto play to true
    if (!this.settings.autoplay) {
      this.props.app.showPlayButton = true;
    }
    // if there is only one playlist
    // make the carousel infinite (carousel will go back to the first slide)
    if (this.props.app.playlist.length <= 1) {
      this.settings.infinite = true;
    }

    // get first slide index
    this.props.app.getCurrentSlide(this.state.activeSlide + 1);
    this.props.app.updateSlickSettings(this.settings);

    // set currentTourTitle
    this.props.app.setCurrentTourTitle(this.props.tour.tour_title[0].text);

    // set window matches
    this.mobileDevice = window.matchMedia('(max-width: 576px)');
    this.tvDesktop = window.matchMedia('(min-width: 2560px)');

    // load first image for imageModal
    let firstSlide = this.props.slides[0];

    if (firstSlide.ui_screen_mobile !== null || firstSlide.ui_screen !== null) {
      this.props.app.getCurrentSlideImage(firstSlide);
    }

    /* EXTRA CODE TO REMOVE BLUR FROM SLIDER WHEN USER HAS ALREADY ENTERED PASSWORD */

    let protectedToursArray = JSON.parse(localStorage.getItem('protectedTours')) || [];
    protectedToursArray.map((id, index) => {
      if (this.props.tour._meta.id === id) {
        this.setState({
          verifiedPassword: true
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.currentSlide !== null &&
      prevProps.currentSlide === this.props.slides.length &&
      this.props.app.settings.autoplay
    ) {
      this.triggerNextTour();
    }
    if (prevState.activeSlide !== this.state.activeSlide) {
      this.props.app.getCurrentSlide(this.state.activeSlide + 1);
      // update slide for modal
      this.props.app.getCurrentSlideImage(this.props.slides[this.state.activeSlide]);
    }

    // component remounts when going to next tour in presentation
    // if presentation mode is on, set autoplay to true and start slider again
    if (this.props.app.presentationMode && !this.props.app.settings.autoplay) {
      this.props.app.updateSlickSettings({ ...this.props.app.settings, autoplay: true });
      return false;
    }
    let protectedToursArray = JSON.parse(localStorage.getItem('protectedTours')) || [];
    protectedToursArray.map((id, index) => {
      if (this.props.tour._meta.id === id && !this.state.verifiedPassword) {
        this.setState({
          verifiedPassword: true
        });
      }
    });

    return false;
  }

  componentWillMount = props => {
    this.clickTimeout = null;
  };

  triggerNextTour = () => {
    setTimeout(() => this.props.checkSlideIndexNext(this.props.app), this.props.slideInterval);
  };

  renderTourLinks = promoArray => {
    if (promoArray[0].promo_label_1 !== null) {
      return (
        <Fragment>
          <div className="resources-header">Resources</div>
          {promoArray[0].promo_label_1 !== null ? (
            <Button
              type="text"
              text={promoArray[0].promo_label_1}
              link={promoArray[0].promo_link_1}
              icon={promoArray[0].promo_link_1_type !== null ? promoArray[0].promo_link_1_type.toLowerCase() : null}
            />
          ) : null}{' '}
          {promoArray[1].promo_label_2 !== null ? (
            <Button
              type="text"
              text={promoArray[1].promo_label_2}
              link={promoArray[1].promo_link_2}
              icon={promoArray[1].promo_link_2_type !== null ? promoArray[1].promo_link_2_type.toLowerCase() : null}
            />
          ) : null}{' '}
        </Fragment>
      );
    }
  };

  renderSlickSlides = ({ lang }) => {
    let { tour } = this.props;
    let backLink = this.props.location.search;

    return this.props.slides.map((step, index) => {
      let promoArray = [];
      let promoObject1 = {
        promo_label_1: step.promo_label_1,
        promo_link_1: step.promo_link_1 !== null ? step.promo_link_1.url : null,
        promo_link_1_type: step.promo_link_1_type !== null ? step.promo_link_1_type : null
      };
      let promoObject2 = {
        promo_label_2: step.promo_label_2,
        promo_link_2: step.promo_link_2 !== null ? step.promo_link_2.url : null,
        promo_link_2_type: step.promo_link_2_type !== null ? step.promo_link_2_type : null
      };
      promoArray.push(promoObject1, promoObject2);
      let stepVideo = step.ui_screen_video !== null ? step.ui_screen_video.url : null;
      return (
        <AppContext.Consumer>
          {app => {
            let { labels } = this.props;
            return (
              <div
                key={index}
                className={`presentation-wrapper ${tour.password === null ? '' : 'password-protected'} ${
                  this.state.verifiedPassword ? 'password-verified' : ''
                }`}
              >
                {/* prevent user from clicking blurry image while modal is open and removing blur for locally stored tours with password access */}
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12">
                        <div className="controls-mobile">
                          <div className="slide-controls-nav">
                            <div className="tour-controls-button-container">
                              <button
                                className="tour-control-btn tour-previous-btn"
                                onClick={() => {
                                  app.slider.slickPrev();
                                  app.slider.slickPause();
                                  app.checkSlideIndexPrev(app, backLink);
                                  app.stopTourAutoPlay();
                                  app.stopPresentationMode();
                                }}
                              >
                                <svg
                                  width="9"
                                  height="16"
                                  viewBox="0 0 9 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M7.05008 0.791616L0.150081 7.62107C-0.0499191 7.82096 -0.0499191 8.1541 0.150081 8.35399L7.05008 15.1834C7.25008 15.3833 7.58341 15.3833 7.78341 15.1834L8.51675 14.4505C8.71675 14.2506 8.71675 13.9175 8.51675 13.7176L3.08341 8.35399C2.88341 8.1541 2.88341 7.82096 3.08341 7.62107L8.48341 2.25745C8.68341 2.05756 8.68341 1.72442 8.48341 1.52453L7.75008 0.791616C7.55008 0.625044 7.25008 0.625044 7.05008 0.791616Z"
                                    fill="#032D60"
                                  />
                                </svg>
                              </button>
                              <StepsCounter totalSteps={this.props.tour.steps.length} />
                              <button
                                className="tour-control-btn tour-next-btn"
                                onClick={() => {
                                  // when a user clicks the next or previous - go to the next slide an pause the slider
                                  app.slider.slickNext();
                                  app.slider.slickPause();
                                  app.checkSlideIndexNext(app, backLink, this.props.currentTourTitle);
                                  app.stopTourAutoPlay();
                                  app.stopPresentationMode();
                                }}
                              >
                                <svg
                                  width="9"
                                  height="16"
                                  viewBox="0 0 9 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M1.94992 0.791616L8.84992 7.62107C9.04992 7.82096 9.04992 8.1541 8.84992 8.35399L1.94992 15.1834C1.74992 15.3833 1.41659 15.3833 1.21659 15.1834L0.483252 14.4505C0.283252 14.2506 0.283252 13.9175 0.483252 13.7176L5.91659 8.35399C6.11659 8.1541 6.11659 7.82096 5.91659 7.62107L0.516585 2.25745C0.316585 2.05756 0.316585 1.72442 0.516585 1.52453L1.24992 0.791616C1.44992 0.625044 1.74992 0.625044 1.94992 0.791616Z"
                                    fill="#032D60"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-8">
                        {/* this needs to be a click event to show */}
                        <>
                          <div className="playlist-select" onKeyDown={e => app.handleKeyPress(e)}>
                            <div className="playlist-select-wrapper">
                              {app.playlist.length > 1 && (
                                <span>
                                  <span className="playlist-menu-image" onClick={() => app.toggleDraggablePlaylist()}>
                                    <img
                                      src={app.showPlaylist ? ClosePlaylistBtn : PlaylistMenuBtn}
                                      alt=""
                                      style={{ pointerEvents: 'none' }}
                                    />
                                  </span>
                                </span>
                              )}
                              <span className="playlist-select-title" style={{ pointerEvents: 'none' }}>
                                {RichText.asText(this.props.tour.tour_title)}
                              </span>
                            </div>
                          </div>
                        </>

                        {(step.ui_screen || step.ui_screen_mobile) && step.ui_screen_video === null ? (
                          <Fragment>
                            <div
                              className={`img-container ${
                                step.ui_screen_mobile === null ? '' : 'mobile-img-container'
                              }`}
                            >
                              <img
                                className={`img-fluid ${
                                  step.ui_screen_mobile === null ? 'faux-browser' : 'mobile-container'
                                }`}
                                src={step.ui_screen_mobile === null ? LargeMonitor : MobileDeviceShadow}
                                alt=""
                              />
                              {(this.mobileDevice !== null && !this.mobileDevice.matches) ||
                              (this.tvDesktop !== null && this.tvDesktop.matches) ? (
                                <div className="zoom-image-container" onClick={e => app.toggleImageModal(e)}>
                                  <img
                                    src={
                                      step.ui_screen_mobile === null ? step.ui_screen.url : step.ui_screen_mobile.url
                                    }
                                    alt={
                                      step.ui_screen_mobile === null ? step.ui_screen.alt : step.ui_screen_mobile.alt
                                    }
                                  />
                                </div>
                              ) : (
                                <div className="zoom-image-container" onClick={e => app.toggleImageModal(e)}>
                                  <div onClick={e => app.toggleImageModal(e)} className="mobile-zoom">
                                    <img
                                      src={
                                        step.ui_screen_mobile === null ? step.ui_screen.url : step.ui_screen_mobile.url
                                      }
                                      alt={
                                        step.ui_screen_mobile === null ? step.ui_screen.alt : step.ui_screen_mobile.alt
                                      }
                                      style={{ width: '100%' }}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="tour-cta-container">
                              <div className="slideshow-toggle-wrapper">
                                <SlideShowToggle
                                  slideShowText={labels.slideshow}
                                  presentationMode={app.presentationMode}
                                />
                              </div>
                              <div className="preview-zoom-container">
                                <div className="mobile-zoom-message">{labels.mobile_zoom_message}</div>
                                <div className="desktop-zoom-message">{labels.desktop_zoom_message}</div>
                              </div>
                            </div>
                          </Fragment>
                        ) : (
                          <>
                            <div className="video-container">
                              <img
                                className={`img-fluid ${
                                  step.ui_screen_mobile === null ? 'faux-browser' : 'mobile-container'
                                }`}
                                src={step.ui_screen_mobile === null ? LargeMonitor : MobileDeviceShadow}
                                alt=""
                              />
                              <div className={`video-wrapper ${app.playlist.length > 1 ? 'has-playlist' : ''}`}>
                                <Player
                                  ref={player => {
                                    this.player = player;
                                  }}
                                  autoPlay={true}
                                  playsInline={true}
                                  muted={true}
                                  loop={true}
                                  preload="auto"
                                  poster="https://images.prismic.io/sf-government-tours-dev/ff09f0fd-695a-436d-b924-12010945dd82_sf-poster.jpg?auto=compress,format"
                                >
                                  <LoadingSpinner />
                                  <source src={stepVideo ? stepVideo : null} />
                                  <ControlBar disableCompletely={true} />
                                </Player>
                              </div>
                            </div>

                            <div className="tour-cta-container">
                              <div className="slideshow-toggle-wrapper">
                                <AppContext.Consumer>
                                  {app => (
                                    <SlideShowToggle
                                      slideShowText={labels.slideshow}
                                      presentationMode={app.presentationMode}
                                    />
                                  )}
                                </AppContext.Consumer>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="col-md-12 col-lg-4">
                        <div className="slide-controls-nav desktop">
                          <StepsCounter totalSteps={this.props.tour.steps.length} />

                          <div className="tour-controls-button-container">
                            <button
                              className="tour-control-btn tour-previous-btn"
                              onClick={() => {
                                app.slider.slickPrev();
                                app.slider.slickPause();
                                app.checkSlideIndexPrev(app, backLink);
                                app.stopTourAutoPlay();
                                app.stopPresentationMode();
                              }}
                            >
                              <svg
                                width="9"
                                height="16"
                                viewBox="0 0 9 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M7.05008 0.791616L0.150081 7.62107C-0.0499191 7.82096 -0.0499191 8.1541 0.150081 8.35399L7.05008 15.1834C7.25008 15.3833 7.58341 15.3833 7.78341 15.1834L8.51675 14.4505C8.71675 14.2506 8.71675 13.9175 8.51675 13.7176L3.08341 8.35399C2.88341 8.1541 2.88341 7.82096 3.08341 7.62107L8.48341 2.25745C8.68341 2.05756 8.68341 1.72442 8.48341 1.52453L7.75008 0.791616C7.55008 0.625044 7.25008 0.625044 7.05008 0.791616Z"
                                  fill="#032D60"
                                />
                              </svg>
                            </button>
                            <button
                              className="tour-control-btn tour-next-btn"
                              onClick={() => {
                                // when a user clicks the next or previous - go to the next slide an pause the slider
                                app.slider.slickNext();
                                app.slider.slickPause();
                                app.checkSlideIndexNext(app, backLink, this.props.currentTourTitle);
                                app.stopTourAutoPlay();
                                app.stopPresentationMode();
                              }}
                            >
                              <svg
                                width="9"
                                height="16"
                                viewBox="0 0 9 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M1.94992 0.791616L8.84992 7.62107C9.04992 7.82096 9.04992 8.1541 8.84992 8.35399L1.94992 15.1834C1.74992 15.3833 1.41659 15.3833 1.21659 15.1834L0.483252 14.4505C0.283252 14.2506 0.283252 13.9175 0.483252 13.7176L5.91659 8.35399C6.11659 8.1541 6.11659 7.82096 5.91659 7.62107L0.516585 2.25745C0.316585 2.05756 0.316585 1.72442 0.516585 1.52453L1.24992 0.791616C1.44992 0.625044 1.74992 0.625044 1.94992 0.791616Z"
                                  fill="#032D60"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                        <span className="title-line">
                          <span>{RichText.render(step.step_title)}</span>
                        </span>
                        <span className="title-line">
                          <span>{RichText.render(step.step_copy)}</span>
                        </span>
                        {this.renderTourLinks(promoArray)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        </AppContext.Consumer>
      );
    });
  };

  render() {
    let { slides, currentSlide, lang } = this.props;
    let realCurrentSlide = currentSlide ? currentSlide - 1 : 0;

    return (
      <AppContext.Consumer>
        {app => {
          return (
            <div className="slick-wrapper">
              {this.state.mobile !== null &&
                (slides[realCurrentSlide].ui_screen_mobile || slides[realCurrentSlide].ui_screen) && (
                  <SliderModal
                    image={
                      slides[realCurrentSlide].ui_screen_mobile && slides[realCurrentSlide].ui_screen_mobile.url
                        ? slides[realCurrentSlide].ui_screen_mobile.url
                        : slides[realCurrentSlide].ui_screen.url
                    }
                    altText={slides[realCurrentSlide].step_title[0].text}
                    desktopOrMobile={slides[realCurrentSlide].ui_screen_mobile ? 'mobile-img' : 'desktop-img'}
                    // goFullScreen={this.state.goFullScreen}
                    // toggleGoFullScreen={() => this.toggleGoFullScreen()}
                  />
                )}
              <div style={{ textAlign: 'center' }} className="slick-hidden-controls">
                <p className="slick-current-slide">{this.state.activeSlide + 1}</p>
              </div>
              <Slider ref={slider => (app.slider = slider)} {...app.settings}>
                {this.renderSlickSlides({ lang })}
              </Slider>
            </div>
          );
        }}
      </AppContext.Consumer>
    );
  }
}
