import React, { Component } from 'react';
import { Link } from 'gatsby';

class PasswordModal extends Component {
  constructor() {
    super();
    this.state = {
      value: '',
      verifiedPassword: false,
      attemptedPassword: false,
      password: null
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({ value: e.target.value, password: e.target.value });
  }

  handleSubmit = e => {
    e.preventDefault();

    let input = document.querySelector('.password-input');
    let modalContainer = document.querySelector('.password-modal-container');
    let siteContainer = document.querySelector('.password-protected');

    // target slick slider to remove blur and enable pointer events
    let presentationWrapper = document.querySelector('.presentation-wrapper');

    if (this.state.password === this.props.password) {
      this.setState({
        verifiedPassword: true
      });
      
      // save tour it to localstorage
      let protectedToursArray = JSON.parse(localStorage.getItem('protectedTours')) || []
      
      let newIDToStore = this.props.tourId;
      protectedToursArray.push(newIDToStore)
      localStorage.setItem('protectedTours', JSON.stringify(protectedToursArray))

      // add classes to remove modal, unblur page and enable pointer events
      modalContainer.classList.add('fade');
      siteContainer.classList.add('password-verified');
      presentationWrapper.classList.add('password-verified');
      siteContainer.classList.remove('password-protected');
    } else {
      this.setState({
        attemptedPassword: true
      });
      /* Timeout to remove wrong password and error message from mobile OPTIONAL*/
      // setTimeout(() => {
      //   this.setState({
      //     value: ''
      //   });
      //   input.value = '';
      // }, 3000);
    }
  };

  componentDidMount() {
    let protectedToursArray = JSON.parse(localStorage.getItem('protectedTours')) || []
    protectedToursArray.map((id, index) => {
      if(this.props.tourId === id) {
        let modalContainer = document.querySelector('.password-modal-container');
        let siteContainer = document.querySelector('.password-protected');
        modalContainer.classList.add('fade');
        siteContainer.classList.add('password-verified');
        siteContainer.classList.remove('password-protected');
      }
    })
  }

  render() {
    return (
      <div className={`password-modal`}>
        <p className="modal-title">Password Protected</p>
        <p>This tour is password protected. To view please enter the provided password below.</p>
        <span className="close">
          <Link to={`/`}>
            <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
              <path
                fill="#222222"
                d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
              ></path>
            </svg>
          </Link>
        </span>
        <form onSubmit={this.handleSubmit} className="password-form">
          <label>Password</label>
          <input type="text" className="password-input" onChange={this.onChange} />
          {this.state.attemptedPassword && !this.state.verifiedPassword ? (
            <p className="error-message">
              Invalid password. Please try again or <span>
                <Link to={`/`} className="error-link">
                  return to home.
                </Link>
              </span>
            </p>
          ) : (
            ''
          )}
          <button disabled={!this.state.value} type="submit">
            Submit
          </button>
        </form>
      </div>
    );
  }
}

export default PasswordModal;
