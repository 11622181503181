import React, { Component } from 'react';
import AppContext from '../context/AppContext';
import CheckIcon from '../_images/global/check-icon.svg';
class PlaylistIntervals extends Component {
  render() {
    return (
      <AppContext.Consumer>
        {app =>
          app.autoPlayIntervals.map((time, index) => {
            return (
              <li
                key={index}
                className={`playlist-intervals-item ${app.defaultTimeInterval === parseInt(time) ? 'active' : ''}`}
                onClick={e => app.updateDefaultTimeInterval(e)}
              >
                <span>{time / 1000} Seconds</span>
                {app.defaultTimeInterval === parseInt(time) && (
                  <span className="check">
                    <img src={CheckIcon} alt="active interval" />
                  </span>
                )}
              </li>
            );
          })
        }
      </AppContext.Consumer>
    );
  }
}
export default PlaylistIntervals;
